/* global $ */
import BaseClass from '../base/baseClass';
import 'slick-carousel';

class Slick extends BaseClass
{
    init() {
        //console.log('Slick initialized');
        $('.slick-carousel').slick({
            autoplay: true,
            arrows: false,
        });
        $('.home-carousel').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade:true,
            autoplaySpeed: 6500,
            speed: 2000,
        });
        $('.inner-carousel').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade:true,
            autoplaySpeed: 4000,
            speed: 2000,
        });
        $('.home-logo-carousel').slick({
            autoplay: false,
            arrows: true,
            dots: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: false,
                    }
                }
            ]
        });
        $('.service-carousel').slick({
            autoplay: false,
            arrows: true,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                    }
                }
            ]
        });
        $('.processes-carousel').slick({
            autoplay: true,
            arrows: true,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        autoplay: true,
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                    }
                }
            ]
        });
        $('.featured-projects-carousel').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
        $('.testimonial-carousel').slick({
            autoplay: true,
            arrows: false,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
        $('.testimonial-video-carousel').slick({
            autoplay: false,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
        $('.related-galleries-carousel').slick({
            autoplay: true,
            arrows: true,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        autoplay: true,
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                    }
                }
            ]
        });
        $('.related-blog-carousel').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        autoplay: true,
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                    }
                }
            ]
        });
 
        if ($(window).width() < 991) {
            $('.blog-carousel').slick({
                autoplay: true,
                arrows: false,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 460,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: true,
                        }
                    }
                ]
            });
        }
        // $(".card-head-area").click(function () {
        //     let $panel = $(this).closest('.card');
        //     if ($(window).width() > 991) {
        //         $('html,body').animate({
        //             scrollTop: $panel.offset().top - 115
        //         }, 400);
        //     }
        //     else {
        //         $('html,body').animate({
        //             scrollTop: $panel.offset().top - 80
        //         }, 400);
        //     }
        // });
    }
}

export default Slick;