/* global $ */
import BaseClass from '../base/baseClass';
import {Tooltip} from 'bootstrap';
import Typed from 'typed.js';

class App extends BaseClass
{
    init()
    {
        $('body').css('opacity', 1);
        this.initTooltips();
        //console.log('App initialized');
        this.initStickyMenu();
        this.initMainMenu();
        this.initMatchHeight();
        this.initAos();
        this.initFileAttach();
        this.initFileAttachJob();
        this.initTab();
        this.contactUsForm();
        this.applyJobForm();
        this.loadMore();
        $(".navbar-toggler").on('click', function () {
            $('body').toggleClass("menuOpen");
            $('.navbar-collapse').toggleClass("open");
        });
        document.querySelectorAll(".element").forEach(function (el) {
            new Typed(el, {
                strings: [el.getAttribute('data-title')],
                typeSpeed: 150,
                backSpeed: 40,
                loop: true,
            });
        });

    }
    initTab() {
        var _this = this;
        _this.tab_accordian(991);
        $(window).resize(function() {
            _this.tab_accordian(991);
        });

        var myGroup = $('#tabGroup');
        $('.tab-head').on('click', function () {
            if(myGroup.find('.collapse')) {
                myGroup.find('.tab-head.active').not($(this)).removeClass('active');
                $(this).toggleClass('active');
                myGroup.find('.collapse.show').removeClass('show');
            }
        });
    }
    tab_accordian(requiredWidth) {
        // Data preparation

        let data = [];
        $('.tab-accordion-container').find('div.tab-item').each(function (index, element) {
            let header = $(element).find('.tab-item-header').html();
            let body = $(element).find('.tab-item-body').html().trim();

            data.push({ 'header': header, 'body': body });
        });

        let html = '';

        if ($(window).width() < requiredWidth) {
            // Accordian html preparation
            let accordionHtml = '<div class="accordion" id="myAccordion">';
            data.forEach(function (element, index) {
                accordionHtml += '<div class="accordion-item">';
                accordionHtml += '<div class="accordion-header">';
                accordionHtml += `<div class="p-1 pe-3 accordion-button-head${index !== 0 ? ' collapsed' : ''}" type="button" data-bs-toggle="collapse" data-bs-target="#myAccordion${index}" aria-expanded="false" aria-controls="collapseTwo">`;
                accordionHtml += element.header;
                accordionHtml += '</div>';
                accordionHtml += '</div>';

                accordionHtml += `<div id="myAccordion${index}" class="accordion-collapse collapse${index === 0 ? ' show' : ''}" data-bs-parent="#myAccordion">`;
                accordionHtml += '<div class="accordion-body p-2">' + element.body + '</div>';
                accordionHtml += '</div>';
                accordionHtml += '</div>';
            });
            accordionHtml += '</div>';

            html = accordionHtml;
        } else {
            let accordianSlNo = 0;
            $('.tab-accordion-container').each(function (index, element) {
                let data = [];
                accordianSlNo++;
                $(element).find('div.tab-item').each(function (index, element) {
                    let header = $(element).find('.tab-item-header').html();
                    let body = $(element).find('.tab-item-body').html().trim();

                    data.push({ 'header': header, 'body': body });
                });

                // Tab head preparation
                let tabHead = `<ul class="nav nav-tabs${accordianSlNo == $('.tab-accordion-container').length ? ' last-nav' : ''}" role="tablist">`;
                data.forEach(function (element, index) {
                    tabHead += '<li class="nav-item" role="presentation">';
                    tabHead += `<a class="nav-link d-flex align-items-center${accordianSlNo == 1 && index === 0 ? ' active deactive' : ''}" data-bs-toggle="tab" data-bs-target="#mytab${accordianSlNo}_${index}" type="button" role="tab">`;
                    tabHead += element.header;
                    tabHead += '</a>';
                    tabHead += '</li>';
                });
                tabHead += '</ul>';

                // Tab body preparation
                let tabBody = '<div class="tab-content">';
                data.forEach(function (element, index) {
                    tabBody += `<div class="tab-pane p-2 p-xxl-2_5 ${accordianSlNo == 1 && index === 0 ? ' show active' : ''}" id="mytab${accordianSlNo}_${index}" role="tabpanel">`;
                    tabBody += element.body;
                    tabBody += '</div>';
                });
                tabBody += '</div>';

                // Tab html preparation
                html = html + tabHead + tabBody;
            });
        }

        $('#tab-accordian-container').html(html);
        $('#tab-accordian-container .nav-tabs a[role="tab"]').click(function () {
            if ($(this).hasClass('deactive')) {
                $('#tab-accordian-container .nav-tabs a.active').removeClass('deactive').removeClass('active');
                $('#tab-accordian-container .tab-content .tab-pane').removeClass('show').removeClass('active');
            } else {
                $(this).addClass('deactive');
                $('#tab-accordian-container .nav-tabs a').not($(this)).removeClass('active').removeClass('deactive');
                $('#tab-accordian-container .tab-content .tab-pane').not($($(this).attr("data-bs-target"))).removeClass('show').removeClass('active');
            }
        });
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();

            $('.blog-box-cnt').matchHeight({
                byRow: false,
                property: 'height',
                target: null,
                remove: false
            });
        });

        $(".navbar-toggler").click(function () {
            $('body').toggleClass("menu-open");
        });

        $('.slick-carousel').slick({
            autoplay: true,
        });
    }

    initMainMenu() {
        $(document).ready(function () {
            $('.navbar .dropdown-toggle').on('click', function (e) {
                var $el = $(this);
                var $parent = $el.offsetParent(".dropdown-menu");
        
                if (!$parent.parent().hasClass('navbar-nav')) {
                    if ($parent.hasClass('show')) {
                        $parent.removeClass('show');
                        $el.next().removeClass('show');
                    } else {
                        $parent.parent().find('.show').removeClass('show');
                        $parent.addClass('show');
                        $el.next().addClass('show');
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        });
    }

    initTooltips()
    {
        $.each($('[data-bs-toggle="tooltip"]'), function (i, elem) {
            new Tooltip(elem);
        });
        $('.file-click-link').on('change',function() {
            var file = $(this)[0].files[0];
            var filename = file.name;
            $(this).parent().find('.displayfilename').html(filename);
            $(this).parent().parent().find('#removeFile').removeClass('d-none');
        });

        $("#removeFile").on('click',function(){
            $(this).addClass('d-none');
            $(this).parent().find('.displayfilename').html('');
            $(this).parent().find('input[type="file"]').val('');
        });

        $( ".indiv-d-none" ).closest( ".benner-btn-area" ).css( "background", "none" );
    }

    initFileAttach() {
        $('.file-click-link').on('change', function () {
            var file = $(this)[0].files[0];
            var filename = file.name;
            $(this).parent().find('.displayfilename').html(filename);
            $(this).parent().parent().find('#removeFile').removeClass('d-none');
        });
        $("#removeFile").on('click',function(){
            $(this).addClass('d-none');
            $(this).parent().find('.displayfilename').html('');
            $(this).parent().find('input[type="file"]').val('');
        });
    }
    initFileAttachJob() {
        $('.file-click-link').on('change', function () {
            var file = $(this)[0].files[0];
            var filename = file.name;
            $(this).parent().find('.displayfilename').html(filename);
            $(this).parent().parent().find('#removeFileJob').removeClass('d-none');
        });
        $("#removeFileJob").on('click',function(){
            $(this).addClass('d-none');
            $(this).parent().find('.displayfilename').html('');
            $(this).parent().find('input[type="file"]').val('');
        });
    }

    initMatchHeight(){
        $(function() {
            $('.process-ttl').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            $('.technologies-title').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            $('.blog-box-cnt').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            $('.service-item .fs-2').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            // $('.blog-box-cnt').matchHeight({
            //     byRow: true,
            //     property: 'height',
            //     target: null,
            //     remove: false
            // });
            $('.box-iocn-ttl').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
        });
    }

    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 100,
                duration: 1500,
                easing: "ease-out-quad",
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }

    contactUsForm() {
        const cntFrm = $('#contact-us-form');
        cntFrm.submit(function (ev) {
            // Prevent the form from actually submitting
            ev.preventDefault();
            const buttonObject = $("#submit");
            const rbtntxt = buttonObject.text();

            const extension = $('#file_id').val().split('.').pop().toLowerCase();
            const extensionsArr = ['pdf'];

            if(extension && $.inArray(extension, extensionsArr) == -1) {
                $("#file").parent().addClass('hasError').find('.field_error').html('<p>Only '+extensionsArr.join(',')+' extensions allowed</p>');
                return false;
            }

            $.ajax({
                type: "POST",
                url: '/wheelform/message/send',
                data: new FormData(this),
                dataType: "json",
                enctype: "multipart/form-data",
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function () {
                    $(".hasError").find('.field_error').html('').parent().removeClass("hasError");
                    buttonObject.html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
                    buttonObject.attr("disabled", "disabled");
                },
                success: function (response) {                    
                    if (response.success) {
                        $(".displayfilename").html('');
                        cntFrm[0].reset();
                        cntFrm.find('#removeFile').addClass('d-none');
                        $('#successMessage').append('<p class="message success">' + response.message + '</p>');
                        const _app = new App();
                        _app.fadeOutMessage('successMessage',5000);
                        grecaptcha.reset();
                    }
                    else {
                        $.each(response.errors, function (key, val) {
                            $("#" + key).parent().addClass('hasError').find('.field_error').html('<p>'+val+'</p>');
                        });
                    }
                    buttonObject.removeAttr("disabled", "disabled");
                    buttonObject.html(rbtntxt);
                },
                error: function (jqXHR, exception) {
                    const msg ='Error ! Please contact System administrator';
                    alert(msg);
                    $('#successMessage').html('<p class="message error">' + msg + '</p>');
                    buttonObject.removeAttr("disabled", "disabled");
                    buttonObject.html(rbtntxt);
                }
            });
        });
    }
    applyJobForm() {
        const cntFrm = $('#apply-job-form');
        cntFrm.submit(function (ev) {
            // Prevent the form from actually submitting
            ev.preventDefault();
            const buttonObject = $("#submitJob");
            const rbtntxt = buttonObject.text();

            const extension = $('#file_id_job').val().split('.').pop().toLowerCase();
            const extensionsArr = ['pdf'];

            if(extension && $.inArray(extension, extensionsArr) == -1) {
                $("#job-attach-file").parent().addClass('hasError').find('.field_error').html('<p>Only '+extensionsArr.join(',')+' extensions allowed</p>');
                return false;
            }

            $.ajax({
                type: "POST",
                url: '/wheelform/message/send',
                data: new FormData(this),
                dataType: "json",
                enctype: "multipart/form-data",
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function () {
                    $(".hasError").find('.field_error').html('').parent().removeClass("hasError");
                    buttonObject.html('<i class="fa fa-spinner fa-spin"></i> Please wait ...');
                    buttonObject.attr("disabled", "disabled");
                },
                success: function (response) {                    
                    if (response.success) {
                        $(".displayfilename").html('');
                        cntFrm[0].reset();
                        cntFrm.find('#removeFileJob').addClass('d-none');
                        $('#successMessageJob').append('<p class="message success">' + response.message + '</p>');
                        const _app = new App();
                        _app.fadeOutMessage('successMessageJob',5000);
                        grecaptcha.reset();
                    }
                    else {
                        $.each(response.errors, function (key, val) {
                            $("#" + key).parent().addClass('hasError').find('.field_error').html('<p>'+val+'</p>');
                        });
                    }
                    buttonObject.removeAttr("disabled", "disabled");
                    buttonObject.html(rbtntxt);
                },
                error: function (jqXHR, exception) {
                    const msg ='Error ! Please contact System administrator';
                    alert(msg);
                    $('#successMessageJob').html('<p class="message error">' + msg + '</p>');
                    buttonObject.removeAttr("disabled", "disabled");
                    buttonObject.html(rbtntxt);
                }
            });
        });
    }

    fadeOutMessage(containerID, timeInterval){
        setTimeout(function(){
            $("#"+containerID).fadeOut(300, function(){
                $("#"+containerID).html('&nbsp;');
            });
        }, timeInterval);
    }

    loadMore(){        
        $(window).scroll(function() {
            // console.log( "sizes... winTop", $(window).scrollTop()," <> doc:" , $(document).height() ," win:", $(window).height(), "footer ht:", $("#footer").height())
            if( $(window).scrollTop() > $(document).height() - ($(window).height() + $("#footer").height()) ) {
                if($("#loadMoreWrapper").length != 0) {
                    // console.log("Click Event-----");
                    $('#loadMore').removeClass('d-none').addClass('show');
                    //$('#loadMore button').trigger('click');
                }
            }
        });

        const loadMoreBtn = document.getElementById('loadMoreBtn');
        const faqItems = document.querySelectorAll('.faq-item');
        let currentCount =5  ;
        const increment = 5 ; // Number of items to show per click
        if (loadMoreBtn) {
            loadMoreBtn.addEventListener('click', function() {
                const nextCount = Math.min(currentCount + increment, faqItems.length);
                for (let i = currentCount; i < nextCount; i++) {
                    faqItems[i].style.display = 'block';
                }
                currentCount = nextCount;

                if (currentCount >= faqItems.length) {
                    loadMoreBtn.style.display = 'none';
                }
            });
        }

    }

}

export default App;